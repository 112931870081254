<template>
  <b-row class="mt-2">
    <b-col
      cols="12"
    >
      <div class="d-flex">
        <b-textarea
          v-model="lock.comment"
          :placeholder="`${$t(section.label)} ...`"
          rows="5"
          size="sm"
          no-resize
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {TEXTAREA_SECTION} from "@/classes/doinsport/Lock";

export default {
  name: TEXTAREA_SECTION,
  data:() => ({
    key: 0,
  }),
   props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    lock: {
      type: Object,
      default: () => {}
    },
  },
}
</script>
